<div class='menu-contain'>
<div class='img-container'>
<img *ngIf='dataIn.top' class='top-line line' src='assets/icons/Line 2.png'>
</div>
<div class='bubble-contain'>
<div class='border-contain'>
<div *ngFor='let pin of hotspots' class='bubble'>
<span (click)='boxDisplayCtrl(pin)' class='text'>{{pin.title}}</span>
</div>
</div>
</div>
<img *ngIf='!dataIn.top' class='bottom-line' src='assets/icons/Line 2.png'>
</div>
