import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HotspotService {

  filters = [];

  constructor() { }

  getFilterColour(filterName){
    if(filterName){
      if(filterName != "map"){
        let filter = this.filters.find(x => x['filterName'] == filterName);
        return filter['filterColour'];
      }
    }
    return "#CF151D";
  }

  setFilters(filters){
    this.filters = filters;
  }
}
