<div *ngIf='show' [ngClass]='{&quot;right-line&quot;: (!left || top), &quot;menu-bubble&quot;: menuHotspot}' class='hotspot'>
<div class='hotspot-container'>
<div class='img-container'>
<img *ngIf='top' [hidden]='menuItem' class='top-line line' src='assets/icons/Line 2.png'>
</div>
<div class='bubble'>
<span class='text'>{{pinTitle}}</span>
</div>
<img *ngIf='!top' [hidden]='menuItem' class='bottom-line line' src='assets/icons/Line 2.png'>
</div>
</div>
