import { animate,style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { ApiService } from '../api.service';
import { DrilldownService } from '../drilldown.service';
import { HotspotService } from '../hotspot.service';
import { AccessoriesService } from '../service/accessories.service';
import { LoadingService } from '../service/loading.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  // animations: [
  //   trigger(
  //     'inOutAnimation',
  //     [
  //       transition(
  //         ':enter',
  //         [
  //           style({
  //             position: 'absolute',
  //             left: 0,
  //             width: '100%',
  //             opacity: 0,
  //             transform: 'scale(0) translateY(100%)',
  //           }),
  //           animate('600ms ease',
  //                   style({ opacity: 1, transform: 'scale(1) translateY(0)' }))
  //         ]
  //       ),
  //       transition(
  //         ':leave',
  //         [
  //           style({ opacity: 1, transform: 'scale(1) translateY(0)' }),
  //           animate('600ms ease',
  //             style({
  //               position: 'absolute',
  //               left: 0,
  //               width: '100%',
  //               opacity: 0,
  //               transform: 'scale(0) translateY(100%)'
  //             }))
  //         ]
  //       )
  //     ]
  //   )
  // ]
})
export class DashboardComponent implements OnInit {
  contactFirstName      = null;
  contactSurname        = null;
  contactEmail          = null;
  contactCompany        = null;
  contactMessage        = null;
  contactPhone          = null;
  contactInterest       = [false,false,false,false];
  contactGDPR           = false;

  //recaptchaVerified     = false;

  mapId: string = null;

  mapData               = null;
  mapBg                 = null;
  mapVid                = null;
  hotspots              = null;
  hotspotFilter         = [];
  menuHotspots          = null;
  boxData               = null;
  boxDataTitle          = null;
  boxDataSubtitle       = null;
  boxDataLogo           = null;
  boxDataDescription    = null;
  boxDataPDF            = null;
  boxDataInterest       = null;
  boxDataImage          = './../assets/images/na-image.jpg';
  boxDataDisplay        = 'intro';
  boxDataVideo          = null;
  boxDataVideoURL       = null;
  boxDataFilter         = null;
  boxDataMaps           = null;

  boxDisplay            = false;
  boxLoaderDisplay      = false;
  boxContentDisplay     = true;
  mobileMenuDisplay     = false;
  mobileReminderDisplay = false;
  GDPRdisplayStatus     = false;
  showTutorial          = false;
  apiURL                = this.ApiService.getAPIURL();

  filterBulk            = true;
  filterPals            = true;
  filterTritec          = true;
  filterNorthern        = true;

  popupVideo            = null;
  popupVideoPlay        = false;
  popupVideoURL         = null;

  showFullscreenPic     = false;
  currentFullscreenPic  = null;

  currentDrilldown      = null;
  contactOpen           = false;

  landingOpen           = true;
  filterSelected        = null;

  subMenuShow           = [];


  constructor(private ApiService: ApiService,
    private DrillDownService: DrilldownService,
    private hotspotService: HotspotService,
    private router: Router,
    private route: ActivatedRoute,
    public accessoriesService: AccessoriesService,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    // this.loadingService.Loading = true;
    // if(localStorage.getItem('filter')){
    //   this.selectFilter(localStorage.getItem('filter'));
    // }
    this.route.params.subscribe(async params => {
      if(!params['id']){
        this.ApiService.getMapWithID(String(1)).subscribe((response: any) => {
          this.resetMap(response.mapTitle);
        })
      }else{
        this.mapId = await params['id'];
        await this.initMap(this.mapId);
      }
    })
  }

  async initMap(id){
    let failed = false;
    (await this.ApiService.getMaps()).pipe(catchError( err => {
      if(err.status === 404){
        failed = true;
        return of(true);
      }
    })).subscribe((response:any) => {
      if(!failed){
        let maps = response;
        let mapTitle = id.replace('-', ' ')
        let map = maps.find(x => x.mapTitle.toLowerCase() == mapTitle);
        if(map != null){
          this.mapData = map;
          this.DrillDownService.setBoxDataInterest(this.mapData['interest']);
          if(this.mapData['mapBackgroundImage']){
            this.mapBg = this.apiURL + this.mapData['mapBackgroundImage']['url'];
          }
          this.mapVid = this.mapData['mapVideoURL'] + '?background=1';
          this.boxDataTitle = this.mapData['mapTitle'];
          this.boxDataLogo = this.apiURL + this.mapData['logoImage']['url'];
          // this.boxDataFilter = this.mapData['filters'];
          this.boxDataMaps = this.mapData['maps'];
          this.initHotspots(this.mapData['hotspot']);
          this.menuHotspots = this.mapData['menuHotspot'];
          this.hotspotService.setFilters(this.boxDataFilter);
          //this.popupVideo = //put the imformation about the video here, normally from JSON;
        }else{
          this.router.navigate(['error']);
        }
      }else{
        this.router.navigate(['error']);
      }
    })
    
  }

  

  // initMenu(arrayIn: any){
  //   let hot = this.mapData['hotspot'];
  //   if(arrayIn !== undefined){
  //     for(let i = 0; i < arrayIn.length; i++){
  //       let menus = [];
  //       arrayIn[i].hotspotList.forEach(li => {
  //         menus.push(hot.find(x => x.title == li.pinTitle));
  //       });
  //       arrayIn[i].hotspotList = menus;
  //     }
  //     return arrayIn;
  //   }else{
  //     return [];
  //   }
  // }

  // selectFilter(filter: string){
  //   this.filterSelected = filter;
  //   this.accessoriesService.Filter = filter
  //   this.landingOpen = false;
  //   this.showTutorial = true;
  //   localStorage.setItem('filter', filter);
  // }

  initHotspots(arrayIn:object): void {
    this.hotspots = arrayIn;
    setTimeout(() => {
      this.loadingService.Loading = false;
    },500)
  }

  resetMap(id: string): void{
    let name = id.replace(' ', '-')
    this.router.navigate(['dashboard', name.toLowerCase()]);
  }



  /*
  resetMap(id): void{
    this.mapData               = null;
    this.mapBg                 = null;
    this.mapVid                = null;
    this.hotspots              = null;
    this.hotspotFilter         = [];
    this.boxData               = null;
    this.boxDataTitle          = null;
    this.boxDataSubtitle       = null;
    this.boxDataLogo           = null;
    this.boxDataDescription    = null;
    this.boxDataPDF            = null;
    this.boxDataInterest       = null;
    this.boxDataImage          = './../assets/images/na-image.jpg';
    this.boxDataDisplay        = 'intro';
    this.boxDataVideo          = null;
    this.boxDataVideoURL       = null;
    this.boxDataFilter         = null;
    this.boxDataMaps           = null;
    this.hotspots              = [];

    this.initMap(id);
  }
  */

  contactCtrl(): void {
    this.DrillDownService.setBoxDataDisplay("contact");
    this.boxDataDisplay    = 'contact';
    this.boxDisplay         = true;
    this.boxContentDisplay  = true;
    this.DrillDownService.contactOpen = true;
    this.DrillDownService.drilldownOpen = true;
    console.log(this.boxDisplay);
  }

  boxDisplayCtrl(arrayIn:any): void {
    this.loadingService.Loading = true;
    this.DrillDownService.setDrillDownData(arrayIn);
    this.router.navigate(['product', arrayIn.route])
  }

  boxDisplayCtrlForMenu(evt: any): void {
    this.loadingService.Loading = true;
    this.DrillDownService.setDrillDownData(evt);
    this.router.navigate(['product', evt.route])
  }

  hotspotDisplay(arrayIn:any): boolean {
    return !arrayIn.filters.split(',').includes(this.filterSelected);
  }

  //Displaying the greyed filter
  filterDisplay(nameIn:string, indexIn:number): boolean {
    if(this.hotspotFilter[indexIn] === nameIn) {
      return true;
    } else {
      return false;
    }
  }

  //Puts the filter into the array
  filterHandler(filterIn:string, indexIn:number): void {
    if(this.hotspotFilter[indexIn]!== filterIn) {
      this.hotspotFilter[indexIn] = filterIn;
    } else {
      this.hotspotFilter[indexIn] = null;
    }
  }

  innerLogoDisplay(filterIn:string): boolean {
    if(this.boxData) {
      if(this.boxData['filters']){
        if (this.boxData['filters'].indexOf(filterIn) >= 0) {
          return false
        } else {
          return true
        }
      }
    } else {
      return true
    }
  }

  boxContentDisplayCtrl(sectionIn:string): void {
    this.boxDataDisplay = sectionIn;
    if(sectionIn == 'video'){
      if(this.boxDataVideo.length >= 0){
        this.boxDataVideoURL = this.boxDataVideo[0]['videoURL'];
      }
    }else{
      this.boxDataVideoURL = null;
    }
  }

  subMenuChange(id: number){
    if(this.subMenuShow.includes(id)){
      this.subMenuShow.splice(this.subMenuShow.indexOf(id), 1);
    }else{
      this.subMenuShow.push(id);
    }
  }

  boxDocumentLauncher(arrayIn:string): void {
    window.open(this.apiURL + arrayIn['file'][0]['url'],'_blank');
  }

  gdprDisplay(): void {
    this.GDPRdisplayStatus = !this.GDPRdisplayStatus;
  }

  gdprConsent(): void {
    this.contactGDPR = !this.contactGDPR;
  }


  playPopupVideo(): void{
    this.popupVideoPlay = !this.popupVideoPlay;
    if(this.popupVideoPlay){
      this.popupVideoURL = this.popupVideo['videoURL'];
    }else{
      this.popupVideoURL = null;
    }
  }

  showFullscreenPicture(pictureURL: string): void{
    this.showFullscreenPic = !this.showFullscreenPic;
    this.currentFullscreenPic = pictureURL;
  }

  drillDownOpen(title: string): boolean{
    if(this.boxDisplay){
      if(title = this.currentDrilldown){
        console.log(title);
        return false;
      }else{
        return true;
      }
    }
    return true;
  }

  getDrillDownOpen(): boolean{
    this.boxDisplay = false;
    return this.DrillDownService.getDrilldownOpen();
  }

  getContactOpen(): boolean{
    this.boxDisplay = false;
    return this.DrillDownService.getContactOpen();
  }

  openAccessories(){
    this.accessoriesService.accessoriesOpen = !this.accessoriesService.accessoriesOpen;
    this.accessoriesService.Hotspots = this.hotspots;
    this.accessoriesService.Filter = this.filterSelected;
  }

  openContact(){
    let link = this.mapData.interest.find(x => x.route.trim() == this.filterSelected.trim()).name;
    if(link){
      window.open(link.trim(), "_blank");
    }
  }
}
