import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { DashboardComponent } from '../dashboard/dashboard.component';

@Component({
  selector: 'app-hotspot-menu',
  templateUrl: './hotspot-menu.component.html',
  styleUrls: ['./hotspot-menu.component.scss']
})
export class HotspotMenuComponent implements OnInit {

  @Input() dataIn: any;
  @Input() hotspotIn: any;
  @Output() pinArray = new EventEmitter<any>();

  hotspots = [];

  constructor(private dashboard: DashboardComponent) { }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.hotspots = this.dataIn;
    // }, 1000)
    this.sortHotspots(this.dataIn, this.hotspotIn);
  }

  sortHotspots(data:any, hotspots:any){
    if(data && hotspots) {
      data['hotspotList'].forEach((hotspotIn:any) => {
        console.log(hotspotIn)
        hotspots.forEach((pinIn:any) => {
          if(pinIn['title'] == hotspotIn['pinTitle']) {
            this.hotspots.push(pinIn);
          }
        })
      })
    }

    console.log(this.hotspots)
  }

  boxDisplayCtrl(pin){
    this.dashboard.boxDisplayCtrl(pin);
    this.pinArray.emit(pin)
  }
}
