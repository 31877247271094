<div class='container'>
<div class='logo'>
<img class='unselectable' src='assets/images/Aviation-logo.png'>
<!-- /Mobile Filters -->
<!-- /.filter.mobile -->
<!-- /  %i.fas.fa-bars.mobile-filter-btn{'(click)' => 'mobileMenuDisplay = !mobileMenuDisplay', '[ngClass]' => '{"fa-times" : mobileMenuDisplay}'} -->
<!-- /Desktop Filters -->
<!-- /.filter.mobile-filter{'[ngClass]' => "{'active-mobile-filter': mobileMenuDisplay}"} -->
<!-- /  .filter-logo-container{'*ngFor' => 'let filter of boxDataFilter', '(click)' => "filterHandler(filter['filterName'],2)", '[ngClass]' => '{"grey-filter": filterDisplay(filter["filterName"],2), "drilldown-filter": getDrillDownOpen()}', '[ngStyle]' => "{'background-color': filter['filterColour']}"} -->
<!-- /    %img{'*ngIf' => 'filter.filterName == "Services"', src: '../assets/icons/services.png'} -->
<!-- /    %img{'*ngIf' => 'filter.filterName == "Structural"', src: '../assets/icons/structural.png'} -->
<!-- /    %img{'*ngIf' => 'filter.filterName == "Explosives"', src: '../assets/icons/explosives.png'} -->
<!-- /    %img.water{'*ngIf' => 'filter.filterName == "Water Control"', src: '../assets/icons/water.png'} -->
<!-- /    %span.unselectable {{filter['filterName']}} -->
<!-- /  .filter-contact{'(click)' => 'contactCtrl()', '[ngClass]' => '{"drilldown-filter": getDrillDownOpen()}'} -->
<!-- /    %img{src: '../assets/icons/noun_Mail_1580478.png'} -->
<!-- /    %span.unselectable Contact -->
</div>
<!-- /Landing page Map -->
<div *ngIf='mapBg' class='map-container'>
<div [ngStyle]='{&#39;background-image&#39;: &#39;url(&#39; + mapBg + &#39;)&#39;}' class='map'>
<!-- /Hot spots -->
<div class='hotspot-container'>
<!-- .object{'*ngFor' => 'let pin of (((hotspots | menu: false) | filter: accessoriesService.Filter) | accessory: false)', '(click)' => "boxDisplayCtrl(pin)" , '[ngStyle]' => '{"width": pin.areaCoordsWidth + "%", "height": pin.areaCoordsHeight + "%", "top": pin.areaCoordsPositionY + "%", "left": pin.areaCoordsPositionX + "%"}'} -->
<app-hotspot-menu (boxDisplayCtrl)='boxDisplayCtrlForMenu($event)' *ngFor='let pin of menuHotspots' [dataIn]='pin' [hotspotIn]='hotspots' [ngStyle]='{&quot;left&quot;: pin.pinPositionX + &quot;%&quot;, &quot;top&quot;: pin.pinPositionY + &quot;%&quot;}'></app-hotspot-menu>
<app-hotspot (click)='boxDisplayCtrl(pin)' *ngFor='let pin of hotspots; index as i' [dataIn]='pin' [ngStyle]='{&quot;left&quot;: pin.pinPositionX + &quot;%&quot;, &quot;top&quot;: pin.pinPositionY + &quot;%&quot;}' class='hotspot-style'></app-hotspot>
<!-- %app-hotspot.hotspot-style{'*ngFor' => 'let pin of (((hotspots | menu: false) | filter: accessoriesService.Filter) | accessory: false) | right: true', '[ngStyle]' => '{"right": pin.pinPositionX + "%", "top": pin.pinPositionY + "%"}', '[dataIn]' => 'pin', '(click)' => "boxDisplayCtrl(pin)", '[hidden]' => 'hotspotDisplay(pin)'} -->
<!-- /%app-hotspot.hotspot-style{'*ngFor' => 'let map of boxDataMaps', '[ngStyle]' => '{"left": map.pinPositionX + "%", "top": map.pinPositionY + "%"}', '[dataIn]' => 'map', '(click)' => 'resetMap(map.title)'} -->
<!-- %app-hotspot.hotspot-style{'[ngStyle]' => '{"right": "72" + "%", "top": "14" + "%"}', '[dataIn]' => 'null', '(click)' => 'openAccessories()'} -->
<!-- .submenu-container.hotspot-style{'*ngFor' => 'let pin of menuHotspots | right: false', '[ngStyle]' => '{"left": pin.pinPositionX + "%", "top": pin.pinPositionY + "%"}'} -->
<!-- %app-hotspot.hotspot-style{'[dataIn]' => 'pin', '(click)' => "subMenuChange(pin['id'])", '[hidden]' => 'hotspotDisplay(pin)', '[ngStyle]' => '{"position": "unset"}'} -->
<!-- %app-hotspot-menu{'[dataIn]' => 'pin["hotspotList"]', '[hidden]' => '!subMenuShow.includes(pin["id"])'} -->
<!-- .submenu-container.hotspot-style{'*ngFor' => 'let pin of menuHotspots | right: true', '[ngStyle]' => '{"right": pin.pinPositionX + "%", "top": pin.pinPositionY + "%"}'} -->
<!-- %app-hotspot.hotspot-style{'[dataIn]' => 'pin', '(click)' => "subMenuChange(pin['id'])", '[hidden]' => 'hotspotDisplay(pin)', '[ngStyle]' => '{"position": "unset"}'} -->
<!-- %app-hotspot-menu{'[dataIn]' => 'pin["hotspotList"]', '[hidden]' => '!subMenuShow.includes(pin["id"])'} -->
</div>
</div>
</div>
<!-- /Into Box -->
<!-- .info-container-filter{'[hidden]' => '!getDrillDownOpen()'} -->
<app-drilldown *ngFor='let pin of hotspots' [dataIn]='pin' [hidden]='pin[&#39;title&#39;] !== currentDrilldown || !getDrillDownOpen()'></app-drilldown>
</div>
<!-- /Mobile swipe reminder -->
