import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }
  token: string = '4adc33e1f9b765b4eeb03525a75b9a';
  apiURL: string = 'https://cms02.the-podium.com';
  collectionPath: string = '/podium-cla-val-airport-maps' ;//'/podium-tritec-stena-maps';
  authPath: string = '/api/cockpit/authUser';
  assetPath: string = '/api/cockpit/assets';

  private isLoggedIn: boolean = false;

  getAPIURL() {
    return this.apiURL;
  }

  getVideo(message: string){
    return message + '.mp4';
  }

  getMapPin(){
    let pinApiURL = this.apiURL + this.collectionPath;
    let obs = this.http.get(pinApiURL);
    return obs;
  }

  async getMaps(){
    let url = this.apiURL + this.collectionPath + '/'
    return this.http.get(url);
  }

  getMapWithID(ID:string){
    let pinApiURL = this.apiURL + this.collectionPath + '/' + ID;
    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getDrilldown(){
    let pinApiURL = this.apiURL + this.collectionPath +  'Drilldown/?token=' + this.token;
    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getContentItems() {
    let pinApiURL = this.apiURL + this.collectionPath +  'ContentItems/?token=' + this.token;
    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getAsset(){
    let pinApiURL = this.apiURL + this.assetPath + '?token=' + this.token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getPoster(StringIn: string) {
    let URL = this.apiURL + '/' + StringIn;
    return URL.toString();
  }

  getPDF(StringIn: string) {
    let URL = this.apiURL + '/' + StringIn;
    return URL.toString();
  }

  getAuth() {
    fetch(this.apiURL + this.authPath + '?token=' + this.token, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            user: 'cfok',
            password: 'cfok'
        })
    })
    .then(user => user.json())
    .then(user => console.log(user));
  }

  isAuthenticated(){
    if(localStorage.getItem('access_token')){
      let jwtDecode = jwt_decode(localStorage.getItem('access_token'));
      if(jwtDecode['exp']*1000 > new Date().getTime()){
        return true;
      }
    }
    return false;
  }

  async getPasswords(){
    return this.http.get('../../assets/password.json').toPromise().then(x => {return x});
  }

  async login(password: string): Promise<boolean>{
    return await this.getPasswords().then((passwords: any) => {
      let passwordFind = passwords.passwords.find(x => x == password);
      if(passwordFind){
        this.isLoggedIn = true;
        localStorage.setItem('loggedIn', 'true');
        const now = new Date();
        now.setDate(now.getDate() + 7);
        localStorage.setItem('dateLoggedInEV', now.toString())
      }
      return !(passwordFind == undefined);
    })
  }

  async checkLoggedIn(){
    if(this.isLoggedIn){
      return true;
    }else{
      if(localStorage.getItem('loggedIn')){
        let dateLogged = localStorage.getItem('dateLoggedInEV');
        const nowDate = new Date();
        if(new Date(dateLogged) > nowDate){
          return true;
        }else{
          localStorage.removeItem('loggedIn');
          localStorage.removeItem('dateLoggedInEV');
          return false;
        }
      }else{
        return false;
      }
    }
  }

  get loggedIn(){
    return this.isLoggedIn;
  }
}
