import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { DashboardComponent }   from './dashboard/dashboard.component';
import { DrilldownComponent } from './drilldown/drilldown.component';
import { LoginComponent } from './login/login.component';
import { CanActivate } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard/:id', component: DashboardComponent },
  {path: 'dashboard', component: DashboardComponent},
  { path: 'product/:id', component: DrilldownComponent},
  { path: 'product', component: DrilldownComponent },
  { path: 'login', component: LoginComponent},
  { path: 'error', component: LoginComponent},
  { path: '**', redirectTo: '/error'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
