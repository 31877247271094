import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccessoriesService {

  accessoriesOpen: boolean = false;
  hotspots = null;
  filter = null;

  constructor() { }

  set Filter(filter){
    this.filter = filter;
  }

  get Filter(){
    return this.filter;
  }

  get Hotspots(){
    return this.hotspots;
  }

  set Hotspots(hotspots){
    this.hotspots = hotspots;
  }

  // checkFilter(): boolean{
  //   if(localStorage.getItem('filter')){
  //     this.filter = localStorage.getItem('filter');
  //     return true;
  //   }else{
  //     return false;
  //   }
  // }
}
