<div class='info-container'>
<div class='inner'>
<div class='inner-header1'>
<div (click)='closeBox()' class='contain'>
<img class='logo' src='../../assets/images/Group 13.png'>
</div>
<div (click)='closeBox()' class='close-contain'>
<img class='close' src='../../assets/icons/noun-plus-5776633.png'>
</div>
</div>
<!-- /Into Box Logo -->
<div class='inner-header'>
<!-- /Normal Title -->
<!-- /%h1{'[hidden]' => "getBoxDataDisplay() == 'contact'"} {{boxDataTitle}} -->
<!-- /Contact Form Title -->
<!-- %h1.contact-title-main{'[hidden]' => "getBoxDataDisplay() !== 'contact'"} Contact -->
<!-- %h1.title-main{'[hidden]' => "getBoxDataDisplay() !== 'document'"} Documents -->
<!-- %h1.title-main{'[hidden]' => "getBoxDataDisplay() !== 'video'"} Videos -->
<!-- /Introduction -->
<div [hidden]='getBoxDataDisplay() != &#39;intro&#39;' [ngClass]='{&#39;display-text-box&#39;: getBoxDataDisplay() == &#39;intro&#39;}' class='text-box text'>
<div class='text-info'>
<h2>{{drilldownService.boxDataTitle}}</h2>
<pre>{{drilldownService.boxDataDescription}}</pre>
<div (click)='openDocument(drilldownService.boxDataPDF[0])' [hidden]='drilldownService.boxDataPDF.length &lt;= 0' class='button'>
<img src='../../assets/icons/noun-download-5006210.png'>
<p>DataSheet</p>
</div>
</div>
<div class='img-contain'>
<img [src]='drilldownService.boxDataImage'>
</div>
</div>
<!-- /Video -->
<!-- /Document -->
</div>
<!-- /Info box menu -->
</div>
</div>
